import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Hero.css'; 
import commercial1 from './images/hero/hero1.jpg';
import commercial2 from './images/hero/hero3.jpg';
import residential1 from './images/hero/hero2.jpg';

function Hero() {
  return (
    <Carousel className="hero-carousel">
      <Carousel.Item interval={8000}>
        <img className="d-block w-100" src={commercial1} alt="Commercial 1" />
        <div className="carousel-caption">
          <h3 className="animated fadeInLeft" style={{  fontWeight: 'bolder' }}>Dreams Do Come True</h3>
          <p className="animated fadeInRight">        Looking to build your dream home? We can make your dreams come true. From concept to completion we take pride in our work and can work exclusively with you to construct your house into a home.
</p>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <img className="d-block w-100" src={commercial2} alt="Commercial 2" />
        <div className="carousel-caption">
          <h3 className="animated fadeInLeft" style={{ fontWeight: 'bolder' }}>Residential, Commercial, Retail</h3>
          <p className="animated fadeInRight">Experts in residential, commercial and retail construction, we have the capacity to tackle every project with the knowledge and expertise that is demanded from inception to completion.</p>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <img className="d-block w-100" src={residential1} alt="Residential 1" />
        <div className="carousel-caption">
          <h3 className="animated fadeInLeft" style={{  fontWeight: 'bolder' }}>Contact Us Today</h3>
          <p className="animated fadeInRight">Reach out to us today to schedule a personalized one-on-one consultation. Our team will work closely with you to develop a customized solution tailored to your specific needs.</p>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Hero;
