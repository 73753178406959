// ImageGallery.js

import React, { useState , useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageGallery.css';

const ImageGallery = ({ project, onClose }) => {

    const galleries = [
        {
            folder: "project1",
            numImages: 12,
        },
        {
            folder: "project2",
            numImages: 9,
        },
        {
            folder: "project3",
            numImages: 10,
        },
        {
            folder: "project4",
            numImages: 9,
        },
        {
            folder: "project5",
            numImages: 3,
        },
        {
            folder: "project6",
            numImages: 16,
        },
        {
            folder: "project7",
            numImages: 13,
        },
        {
            folder: "project8",
            numImages: 13,
        },
        {
            folder: "project9",
            numImages: 19,
        },
        {
            folder: "project10",
            numImages: 21,
        },
        {
            folder: "project11",
            numImages: 5,
        },
        {
            folder: "project12",
            numImages: 3,
        },
        {
            folder: "project13",
            numImages: 16,
        }
    ];

    const folderName = project.image.split('/').slice(-1)[0].split('.')[0];
    const selectedGallery = galleries.find(gallery => gallery.folder === folderName);
    const numImages = selectedGallery ? selectedGallery.numImages : 5;

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const [currentSlide, setCurrentSlide] = useState(0);

    const handleClickThumbnail = (index) => {
        // Handle thumbnail click to navigate to the corresponding image
        setCurrentSlide(index);
    };

    useEffect(() => {
        // Update the slider's current slide when the currentSlide state changes
        slider.slickGoTo(currentSlide);
    }, [currentSlide]);

    let slider; // Reference to the Slider component


    if (!project || !project.name || !project.image) {
        return <div>No project data available</div>;
    }

     // Function to generate image paths for both PNG and JPG formats
    const getImagePaths = () => {
        const images = [];
        for (let i = 1; i <= numImages; i++) {
            const imagePathPNG = `./images/job_site/${folderName}/project${i}.png`;
            const imagePathJPG = `./images/job_site/${folderName}/project${i}.jpg`;
            images.push(imagePathPNG, imagePathJPG);
        }
        return images;
    };

    const imagePaths = getImagePaths();

    return (
        <div className="image-gallery-container">
            <button className="close-btn" onClick={onClose}>Close</button>
            <div className="scrollable-navigation">
                <div className="thumbnails">
                    {Array.from({ length: numImages }, (_, i) => (
                        <div key={i} className="thumbnail-item" onClick={() => handleClickThumbnail(i)}>
                            <img
                                className="thumbnail-image"
                                src={`./images/job_site/${folderName}/project${i + 1}.png`}
                                alt={`Project ${i + 1}`}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <Slider {...settings} ref={slick => (slider = slick)}>
                {Array.from({ length: numImages }, (_, i) => (
                    <div key={i}>
                        <img
                            className="d-block w-100"
                            src={`./images/job_site/${folderName}/project${i + 1}.png`}
                            alt={`Project ${i + 1}`}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
}



export default ImageGallery;
