import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Card, Image} from 'react-bootstrap';
import './Body.css';
import ProjectGrid from './ProjectGrid'; // Import the ProjectGrid component


const Body = () => {
  useEffect(() => {
    ReactGA.initialize('G-33D3BTPGBG'); // Replace with your Google Analytics 4 tracking ID
    ReactGA.send('pageview');
  }, []);

  return (
    <div className="body-container">

      <div className="content-wrapper">

      <div className="body-row">
      <div className="card-container">
      <Card>
        <Card.Title><h1 className='h1Label'>Mission Statement</h1>

</Card.Title>
          <Card.Body>
            
            <Card.Text>
            
           <p> At Grand Terra Developments, we blend innovation with functionality. With over 38 years of leadership in the construction industry, we’ve proudly crafted fine properties all across Ontario. Our commitment to unprecedented service and quality drives us to create turn-key solutions for remodeling, renovating, and building. We enhance property value while maintaining a cost-effective approach, closely collaborating with project professionals and clients. Join us on this journey as we build a better, more functional future.</p>
           <h4 style={{ color: 'rgb(167, 12, 12)'}} >Bringing people together for a better way of life!</h4> 
          
            </Card.Text>
            
          </Card.Body>
        </Card>
        </div>
        <div className="card-container">
          
        <Card>
        <Card.Title><h1 className='h1Label'>Our Services</h1></Card.Title>
        <Card.Body>
          <Card.Text>
          <h2 className='h2Label'>Services Offered</h2> 

    <div style={{width: "100%", overflow: "hidden"}}>
          <ul>
            <li style={{ fontSize: '18px'}}>New custom homes (2000-7000 sq.ft)</li>
            <li style={{ fontSize: '18px'}}>Residential additions and renovations</li>
            <li style={{ fontSize: '18px'}}>Apartment restorations and property improvements</li>
            <li style={{ fontSize: '18px'}}>Corporate office renovations</li>
            <li style={{ fontSize: '18px'}}>Retail chain store construction</li>
            <li style={{ fontSize: '18px'}}>Interior flood damage repairs</li>
            <li style={{ fontSize: '18px'}}>Project management</li>
          </ul>
          </div>

          <div style={{display: "flex", justifyContent: "space-between"}}>
    <span style={{flexGrow: 1, marginRight: "20px", maxWidth: "100%"}}>

    </span>

    <span style={{flexGrow: 1, marginLeft: "20px", maxWidth: "50%"}}>
    </span>
</div>

          <h2 className='h2Label'>Service Coverage</h2>
       
       <span> Operating across all of Ontario, Grand Terra Developments is the preferred choice for clients seeking comprehensive contracting solutions.
 With two offices strategically located in Ontario, we provide convenient points of access for our clients. This approach ensures flexibility and facilitates interactions within our organization.
 </span> 
         
          </Card.Text>     
         
        </Card.Body>
                </Card>

        <Card>
        <Card.Title><h1 className='h1Label'>Client Focus</h1></Card.Title>
          <Card.Body>
            
            <Card.Text>
              <div >
            <h2 className='h2Label'>Commitment to Clients</h2>   
           <span> Our friendly and professional approach fosters trust and loyalty among our clients. We work closely with each and every client from design to construction, ensuring that your specific requirements are met and expectations are exceeded, while ensuring reasonable timelines are established and adhered to.</span>
         
              <h2 className='h2Label'>Commitment to Quality</h2>   
              <p> Our quality management system ensures the smooth execution of contracts and guarantees consistent high-quality results for every project. We adhere to all relevant health and safety regulations, as well as maintaining commercial liability insurance and WSIB compliance.</p>
            
              <h2 className='h2Label'>Commitment to Service</h2>   
              <p>At our core, we prioritize our clients. Our friendly and professional approach fosters trust and loyalty among those we serve. From the initial design phase to the final construction, we work closely with each and every client. Our goal is to ensure that your specific requirements are not only met but exceeded. We understand the importance of reasonable timelines, and we are committed to adhering to them.</p>
            
            <h4 style={{ color: 'rgb(167, 12, 12)'}}>Our strength is building!</h4>
            </div> </Card.Text>
            
          </Card.Body>
        </Card>

      </div>
      <div className="card-container">
      <Card>
        <Card.Title><h1 className='h1Label'>Our Portfolio</h1>
        </Card.Title>
        <Card.Body>
     <div className="body-row portfolio">
      
     <Card.Text>
      <div className='body-partners'>
     <p>Our esteemed clientele includes industry leaders such as:</p> 
     </div>
     </Card.Text>
     <div className='card-body-logo-parent'>
    <div className="card-body-logo-wrapper">

<div className="logo-container-2">
  <Image src="./images/4.webp" alt="Del Logo" className="logo-1" />
</div>

<div className="logo-container-2">
  <Image src="./images/3.png" alt="Timbercreek Logo" className="logo-2" />
</div>
<div className="logo-container-2">
  <Image src="./images/1.png" alt="Online Logo" className="logo-2" />
</div>
<div className="logo-container-2">
  <Image src="./images/bf.svg.png" alt="Brookfield Logo" className="logo-2" />
</div>
<div></div>
</div>
</div>
<div className='card-body-logo-parent'>
<div className="card-body-logo-wrapper">
<div className="logo-container-2">
  <Image src="./images/2.png" alt="Harmony Logo" className="logo-2" />
</div>

<div className="logo-container-2">
  <Image src="./images/crossbridge.png" alt="Crossbridge Logo" className="logo-2" />
</div>

</div>
</div>
       <div className="card-grid">
         <ProjectGrid />
       </div>
     </div> 
            </Card.Body>
          </Card>
      </div>
      </div>
    </div>
    </div>
  );
};

export default Body;