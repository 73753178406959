import React from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import './Footer.css'; // Add this line to import the CSS


function Footer() {
  return (
    <footer>
      <div className="footer-row first-row">
        <div >
        <span className="address-line" style={{ color: 'rgb(167, 12, 12)', textDecoration: 'none', fontWeight: 'bolder' }}>Head Office:  </span>
        <span className="address-line">55 Commerce Valley Dr. W.</span>
        <span className="address-line">&nbsp;Thornhill ON, L3T 7V9  </span>

        
        </div>
      <div >
      
      <span className="address-line" style={{ color:  'rgb(167, 12, 12)', textDecoration: 'none', fontWeight: 'bolder' }}>&nbsp;&nbsp;&nbsp;&nbsp;Office:  </span>
<span className="address-line">4025 Dorchester Rd.</span>
<span className="address-line">&nbsp;Niagara Falls ON, L2E 7K8  </span>

        </div>
      </div>
      <div className="footer-row middle-row" >
  <span className="phone-line">
    <FaPhone /> <a href="tel:6478657850" style={{ color: 'inherit', textDecoration: 'none' }}>647.865.7850</a>
  </span>

  
  <span className="email-line">
    <FaEnvelope /> <a href="mailto:info@grandterradevelopments.ca" style={{ color: 'inherit', textDecoration: 'none' }}>info@grandterradevelopments.ca</a>
  </span>
</div>

      <div className="footer-row last-row">
        <span className="copyright">© 2024 Grandterra Developments. All rights reserved.</span>
      </div>
    </footer>
  );
}

export default Footer;