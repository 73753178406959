// components/Header.js
import { Container, Row, Col, Image, Button, Modal, Form, Nav, Navbar, NavDropdown, NavLink } from 'react-bootstrap';
import React, { useState } from 'react';
import './Header.css';
import ReactGA from 'react-ga4';
import ContactForm from './ContactForm'; // Import the ContactForm component

function Header() {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    ReactGA.event('modal_close', 'contact_form'); // Track modal close
  };
  const handleShow = () => {
    setShowModal(true);
    ReactGA.event('modal_open', 'contact_form'); // Track modal open
  };

  return (
    <div className="header">
      <Row className="align-items-left row-height">
        <Col xs={2} sm={2} className="logo-gtd-container d-flex justify-content-left text-center">
          <Image src="./images/GTD-logo.jpg" alt="GTD Logo" className="logo-gtd" />
        </Col>
        <Col xs={4} sm={5} className="my-auto">
          <div className="text-container">
            <h6 style={{ color:  'rgb(167, 12, 12)', textDecoration: 'none', fontWeight: 'bolder' }}>Experts In:</h6>
            <ul className="header-list">
              <li className="header-list-item">Construction Management</li>
              <li className="header-list-item">Residential and Commercial</li>
              <li className="header-list-item">Architectural Design</li>
            </ul>
           <button className="contact-button" onClick={handleShow}>Contact Us</button>
          </div>
        </Col>
        <Col xs={1} sm={1} className="logo-parent">
          <div className="logo-wrapper">
            <div className="logo-container-2">
              <Image src="./images/4.webp" alt="Del Logo" className="logo-1" />
            </div>
            <div className="logo-container-2">
              <Image src="./images/3.png" alt="Timbercreek Logo" className="logo-2" />
            </div>
            <div className="logo-container-2">
              <Image src="./images/1.png" alt="Online Logo" className="logo-2" />
            </div>
            <div className="logo-container-2">
              <Image src="./images/2.png" alt="Harmony Logo" className="logo-2" />
            </div>
            <div className="logo-container-2">
              <Image src="./images/bf.svg.png" alt="Brookfield Logo" className="logo-2" />
            </div>
            <div className="logo-container-2">
              <Image src="./images/crossbridge.png" alt="Crossbridge Logo" className="logo-2" />
            </div>
          </div>
        </Col>
      </Row>
      <div className="horizontal-line-container">
        <div className="horizontal-line">
          <Navbar>
            <NavLink className="header-nav-link active" onClick={handleShow}>
              Contact Us
            </NavLink>
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Contact Us</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <ContactForm handleClose={handleClose} isOpen={showModal} /> {/* Pass handleClose and isOpen as props */}
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Navbar>
        </div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </div>
  );
}

export default Header;
