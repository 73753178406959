import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import './ProjectGrid.css';
import ImageGallery from './ImageGallery';

// Create a new component for the project card
function ProjectCard({ project, index, onClick }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 1, delay: index * 0.1 }} // Add delay based on index
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 }
      }}
      key={index}
      onClick={onClick}
      className="project-card"
    >
      <img src={project.image} alt={project.name} />
      <div className="project-card-text">{project.hoverText}</div>
    </motion.div>
  );
}

function ProjectGrid() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  
  const projects = [
    {
      name: "Project 1",
      image: "./images/job_site/project1.webp",
      hoverText: "Project 1",
    },
    {
      name: "Project 2",
      image: "./images/job_site/project2.webp",
      hoverText: "Project 2",
    },
    {
      name: "Project 3",
      image: "./images/job_site/project3.webp",
      hoverText: "Project 3",
    },
    {
      name: "Project 4",
      image: "./images/job_site/project4.webp",
      hoverText: "Project 4",
    },
  
    {
      name: "Project 6",
      image: "./images/job_site/project6.webp",
      hoverText: "Project 6\nValue $2,790,000",
      
    },
    {
      name: "Project 7",
      image: "./images/job_site/project7.webp",
      hoverText: "Project 7\nValue $1,865,000",
    },
    {
      name: "Project 8",
      image: "./images/job_site/project8.png",
      hoverText: "Project 8\nValue $2,050,000",
    },
    {
      name: "Project 9",
      image: "./images/job_site/project9.png",
      hoverText: "Project 9\nValue $1,920,000",
    },
    {
      name: "Project 10",
      image: "./images/job_site/project10.webp",
      hoverText: "Project 10\nValue $2,600,000",
    },
    {
      name: "Project 11",
      image: "./images/job_site/project12.png",
      hoverText: "Project 11",
    },
    {
      name: "Project 12",
      image: "./images/job_site/project13.png",
      hoverText: "Additional Project Gallery",
    },
    {
      name: "Commercial",
      image: "./images/job_site/project11.jpg",
      hoverText: "Commercial Projects",
    },
  ];

  const openGallery = (project) => {
    setSelectedProject(project);
    setShowGallery(true);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setSelectedProject(null);
    setShowGallery(false);
    setGalleryOpen(false);
  };

  useEffect(() => {
    if (galleryOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Clean up the effect
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [galleryOpen]);

  return (
    <div>
      <section className="project-grid">
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            project={project}
            index={index}
            onClick={() => openGallery(project)}
          />
        ))}
      </section>
      {showGallery && selectedProject && (
        <div className="overlay">
          <div className="overlay-content">
            <ImageGallery project={selectedProject} onClose={closeGallery} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectGrid;