// App.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Body from './components/Body';
import Footer from './components/Footer';
import './index.css';
import ReactGA from 'react-ga4';
import { FloatingWhatsApp } from 'react-floating-whatsapp';


ReactGA.initialize('G-33D3BTPGBG'); 


function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Body />
      <Footer />
      <FloatingWhatsApp
      phoneNumber="16478657850"
      accountName="Grand Terra Developments"
    />
    </div>
  );
}

export default App;