// components/ContactForm.js
import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import './ContactForm.css'; 
import ReactGA from 'react-ga4';

const ContactForm = ({ handleClose, isOpen }) => { // Add isOpen prop
  useEffect(() => {
    if (isOpen) {
      ReactGA.event('modal_view', 'contact_form');
    }
  }, [isOpen]);

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('https://www.grandterradevelopments.ca/send_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Network Error CF1021');
      }
  
      const data = await response.json();
  
      // Check if the email was sent successfully
      if (data.message === 'Thankyou for your interest in Grand Terra Developments.Your message was sent successfully and we will be in contact with you shortly.') {
        alert('Email sent successfully!');
        handleClose(); // Close the modal
        ReactGA.event('form_submission', 'contact_form'); // Track form submission
      } else {
        throw new Error('Error CF100121');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email: ' + error.message);
    }
  };
  
  return (
    <Container className="contact-form-container">
      <Form onSubmit={handleSubmit} className="contact-form">
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Label>Location Address</Form.Label>
          <Form.Control
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="message">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="contact-form-button">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default ContactForm;
